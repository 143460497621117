<template>
  <div>
    <b-card title="Edit Event">
      <b-tabs content-class="mt-2" pills>
        <b-tab>
          <template #title>
            <feather-icon icon="FileTextIcon" />
            <span>Event Details</span>
          </template>
          <b-form>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Id" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.pkid_mxs_event" plaintext />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Event Name" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.event_name" :plaintext="togglePlaintext" />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Aforo Name" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.Aforo_name" plaintext />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Season" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.Temporada" plaintext />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Id Event" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.ID_Event" :plaintext="togglePlaintext" />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Competition" label-for="input-default">
                  <b-form-input id="input-default" v-model="body.Competition_name" :plaintext="togglePlaintext" />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>
            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Event Active" label-for="input-default">
                  <b-form-select v-model="body.mxs_event_Active" :options="options" :disabled="disable" />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Update Time" label-for="input-default">
                  {{ dateFormat(body.timestamp_update) }}
                  <b-form-input id="input-default" v-model="body.timestamp_update" plaintext />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>

            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Wristband" label-for="input-default">
                  <b-form-checkbox v-model="body.wristband" :options="options" :disabled="disable" />
                </b-form-group>
              </b-col>
              <b-col cols="1"></b-col>
              <b-col>
              </b-col>
              <b-col cols="1"></b-col>
            </b-row>


          </b-form>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon icon="CalendarIcon" />
            <span>Event Dates</span>
          </template>
          <b-form>

            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Event Date" label-for="input-default">
                  <flat-pickr v-model="body.Date" class="form-control" :disabled="disable" :config="{
                    enableTime: true,
                    time_24hr: true,
                  }" />
                </b-form-group>
              </b-col>
              <b-col cols="4"></b-col>
              <b-col cols="2"></b-col>
              <b-col cols="1"></b-col>
            </b-row>


            <b-row>
              <b-col cols="1"></b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Accred. Start" label-for="input-default">
                  <flat-pickr v-model="body.timestamp_accreditation_start" class="form-control" :disabled="disable" :config="{
                    enableTime: true,
                    time_24hr: true,
                  }" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Accred. End" label-for="input-default">
                  <flat-pickr v-model="body.timestamp_accreditation_end" class="form-control" :disabled="disable" :config="{
                    enableTime: true,
                    time_24hr: true,
                  }" />
                </b-form-group>
              </b-col>
              <b-col cols="2"></b-col>
              <b-col cols="1"></b-col>
            </b-row>

            <b-row>
              <b-col cols="1"></b-col>              
              <b-col>
                <b-form-group label-cols-lg="2" label="Wristband Start" label-for="input-default">
                  <flat-pickr v-model="body.timestamp_wristband_start" class="form-control" :disabled="disable || !body.wristband" :config="{
                    enableTime: true,
                    time_24hr: true,
                  }" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label-cols-lg="2" label="Wristband End" label-for="input-default">
                  <flat-pickr v-model="body.timestamp_wristband_end" class="form-control" :disabled="disable || !body.wristband" :config="{
                    enableTime: true,
                    time_24hr: true,
                  }" />
                </b-form-group>
              </b-col>
              <b-col cols="2"></b-col>      
              <b-col cols="1"></b-col>
            </b-row>
          </b-form>
        </b-tab>
      </b-tabs>
      <b-row></b-row>
      <b-row>
        <b-col></b-col>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" @click="message()" :hidden="hiddenComponent" >
          <feather-icon icon="SaveIcon" class="mr-0 mr-sm-50"></feather-icon>
          Update Event
        </b-button>
        <b-button variant="outline-primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" to="/events">
          <feather-icon icon="ArrowLeftCircleIcon" class="mr-0 mr-sm-50"></feather-icon>
          Go Back
        </b-button>
        <b-col></b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BTabs,
  BTab,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BRow,
  BCol,
  BFormSelect,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import router from "@/router";
import { ref } from "@vue/composition-api";

import axios from "@/libs/axios";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BRow,
    BCol,
    BFormSelect,

    flatPickr,
  },
  methods: {
    async message() {
      const error = await this.onSubmit();
      if(error[0]) {
        const variant = "danger";
        this.$bvToast.toast("" + error[1], {
          title: "Failed",
          variant,
          solid: true,
        });
      } else if (!error[0]) {
        this.togglePlaintext = true;
        this.disable = true;
        this.hiddenComponent = true;
        const variant = "success";
        this.$bvToast.toast("Event updated correctly", {
          title: `Update`,
          variant,
          solid: true,
        });
      }
    },
    dateFormat(date) {
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      if (date) {
        const d = new Date(date);
        let minutes = d.getMinutes();
        if (d.getMinutes() === 0) {
          minutes = '00'
        }
        const finalDate = d.getDate() + ' ' + months[d.getMonth()] + ' ' + d.getFullYear() + ' ' + d.getHours() + ':' + minutes;
        this.body.timestamp_update = finalDate;
      }
    },
  },
  data() {
    return {
      disable: false,
      hiddenComponent: false,
      togglePlaintext: false,
      selected: null,
      options: [
        { value: "true", text: 'Active' },
        { value: "false", text: 'Inactive' },
      ],
    };
  },
  created() {
    axios
      .get(
        "/event" + "?pkid_mxs_event=" + router.currentRoute.params.id
      )
      .then((result) => {
        this.body = result.data[0];

        let date = this.body.Date.split("T").join(" ")
        date = this.body.Date.split("Z").join("")
        this.body.Date = date

        let updateDate = this.body.timestamp_update.split("T").join(" ")
        updateDate = this.body.timestamp_update.split("Z").join("")
        this.body.timestamp_update = updateDate

        let accredStartDate = this.body.timestamp_accreditation_start.split("T").join(" ")
        accredStartDate = this.body.timestamp_accreditation_start.split("Z").join("")
        this.body.timestamp_accreditation_start = accredStartDate

        let accredEndDate = this.body.timestamp_accreditation_end.split("T").join(" ")
        accredEndDate = this.body.timestamp_accreditation_end.split("Z").join("")
        this.body.timestamp_accreditation_end = accredEndDate

        
        let wristbandStartDate = this.body.timestamp_wristband_start.split("T").join(" ")
        wristbandStartDate = this.body.timestamp_wristband_start.split("Z").join("")
        this.body.timestamp_wristband_start = wristbandStartDate

        let wristbandEndDate = this.body.timestamp_wristband_end.split("T").join(" ")
        wristbandEndDate = this.body.timestamp_wristband_end.split("Z").join("")
        this.body.timestamp_wristband_end = wristbandEndDate
      })
      .catch();
  },
  mounted(){
    if (localStorage.getItem("accessToken") === null) {
      this.$router.go(0)
    }
  },
  setup() {
    const blankEventData = {
      pkid_mxs_event: "",
      Temporada: "",
      Aforo_name: "",
      ID_Aforo: "",
      ID_Event: "",
      event_name: "",
      ID_Competition: "",
      Competition_name: "",
      Date: "",
      mxs_event_Active: "",
      timestamp_accreditation_start: "",
      timestamp_accreditation_end: "",
      timestamp_created: "",
      timestamp_update: "",
      wristband:"",
      timestamp_wristband_start: "",
      timestamp_wristband_end: "",
    };

    const body = ref(JSON.parse(JSON.stringify(blankEventData)));

    const onSubmit = async () => {
      const onError = [false, ""];
      await axios
        .put("/event", body.value)
        .then((result) => {
          onError[0] = false;
        })
        .catch((error) => {
          onError[0] = true;
          onError[1] = error;
        });
        return onError;
    };

    return {
      body,
      onSubmit,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.row {
  padding-top: 0.5%;
  padding-bottom: 0.5%;
}
</style>
